@import "colors";
@import "typography";
@import "breakpoints";

.carousel {
  position: absolute;
  width: 100vw;
  height: 100vh;
}

.carousel-item {
  width: 100vw;
  height: 100vh;

  img {
    width: 100vw;
    height: 100vh;
    object-fit: cover;

    &.img1 {
      content: url("../assets/img/gallery/1-min.jpg");
      @include size-down(md) {
        content: url("../assets/img/gallery/1m-min.jpg");
      }
    }

    &.img2 {
      content: url("../assets/img/gallery/2-min.jpg");
      @include size-down(md) {
        content: url("../assets/img/gallery/2m-min.jpg");
      }
    }

    &.img3 {
      content: url("../assets/img/gallery/3-min.jpg");
      @include size-down(md) {
        content: url("../assets/img/gallery/3m-min.jpg");
      }
    }

    &.img4 {
      content: url("../assets/img/gallery/4-min.jpg");
      @include size-down(md) {
        content: url("../assets/img/gallery/4m-min.jpg");
      }
    }

    &.img5 {
      content: url("../assets/img/gallery/5-min.jpg");
      @include size-down(md) {
        content: url("../assets/img/gallery/5m-min.jpg");
      }
    }

    &.img6 {
      content: url("../assets/img/gallery/6-min.jpg");
      @include size-down(md) {
        content: url("../assets/img/gallery/6m-min.jpg");
      }
    }

    &.img7 {
      content: url("../assets/img/gallery/7-min.jpg");
      @include size-down(md) {
        content: url("../assets/img/gallery/7m-min.jpg");
      }
    }

    &.img8 {
      content: url("../assets/img/gallery/8-min.jpg");
      @include size-down(md) {
        content: url("../assets/img/gallery/8m-min.jpg");
      }
    }

    &.img9 {
      content: url("../assets/img/gallery/9-min.jpg");
      @include size-down(md) {
        content: url("../assets/img/gallery/9m-min.jpg");
      }
    }

    &.img10 {
      content: url("../assets/img/gallery/10-min.jpg");
      @include size-down(md) {
        content: url("../assets/img/gallery/10m-min.jpg");
      }
    }

    &.img11 {
      content: url("../assets/img/gallery/11-min.jpg");
      @include size-down(md) {
        content: url("../assets/img/gallery/11m-min.jpg");
      }
    }

    &.img12 {
      content: url("../assets/img/gallery/12-min.jpg");
      @include size-down(md) {
        content: url("../assets/img/gallery/12m-min.jpg");
      }
    }

    &.img13 {
      content: url("../assets/img/gallery/13-min.jpg");
      @include size-down(md) {
        content: url("../assets/img/gallery/13m-min.jpg");
      }
    }

    &.img14 {
      content: url("../assets/img/gallery/14-min.jpg");
      @include size-down(md) {
        content: url("../assets/img/gallery/14m-min.jpg");
      }
    }
  }

  .info {
    width: 360px;
    position: absolute;
    left: 0;
    bottom: 0;
    margin: 48px;
    padding-top: 40px;
    background-image: url('../assets/img/info.svg');
    background-repeat: no-repeat;
    background-size: 12px 24px;

    @include size-down(lg) {
      width: 200px;
    }
  }
}

.booking-logo {
  text-align: left;
  display: block;
  position: absolute;

  @include size-down(md) {
    display: none;
  }

  img {
    width: 80px;
    height: 80px;
    margin: 48px;
    content: url('../assets/img/logo.svg');
  }
}

.booking-logo-form {
  width: 100%;

  @include size-up(md) {
    display: none;
  }

  img {
    width: 80px;
    height: 80px;
    margin-bottom: 32px;
    content: url('../assets/img/logo.svg');
  }
}

.form {
  position: relative;
  width: 440px;
  height: 100vh;
  float: right;
  margin: auto;
  background: $color-background;

  @include size-down(lg) {
    width: 540px;
  }

  @include size-down(md) {
    width: 100%;
  }

  .form-wrapper {
    height: 100%;
    position: relative;
    overflow-y: scroll;
    padding: 40px 40px 140px 40px;

    @include size-down(sm) {
      padding: 24px 24px 120px 24px;
    }
  }

  .buttons-wrapper {
    background: $color-background;
    position: absolute;
    padding: 40px;
    left: 0;
    right: 0;
    bottom: 0;
    border-top: 1px solid #405056;

    @include size-down(sm) {
      padding: 24px;
    }
  }

  .form-step {
    @include h3-fixed;
    color: #B9794A;
    margin-bottom: 16px;
  }

  .form-title {
    @include h1-fixed;
    margin-bottom: 16px;
  }

  .form-hint {
    @include p2-fixed;
    color: $color-secondary;
    margin-bottom: 40px;
  }

  .form-error {
    @include p2-fixed;
    color: $color-danger;
    margin-bottom: 20px;
  }

  .form-group {
    position: relative;
    padding: 8px;
    background: $color-background;
    border: 1px solid $color-secondary;
    box-sizing: border-box;
    border-radius: 2px;
    margin-bottom: 16px;
    outline: 0 !important;

    @include size-down(md) {
      position: static;
    }

    label {
      @include p5-fixed;
    }

    &.error {
      border: 1px solid $color-danger;

      label {
        color: $color-danger;
      }
    }
  }

  input, textarea {
    @include p1-fixed;
    padding: 16px;
    color: $color-foreground;
    background-color: $color-background;
    border: none;
    transition: none;

    &:focus {
      color: $color-foreground;
      background-color: $color-background;
      border: none transparent;
      box-shadow: none !important;
    }

    &:disabled {
      background: none;
    }
  }

  .form-checkbox {
    position: relative;
    margin-top: 16px;
    margin-bottom: 16px;
    min-height: 40px;
    cursor: pointer;

    label {
      @include p2-fixed;
      padding-left: 56px;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark::after {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 40px;
      height: 40px;
      border: 1px solid #6B7174;
      box-sizing: border-box;
      border-radius: 2px;
    }

    &.error .checkmark::after {
      border: 1px solid #FF6060;
    }

    input:checked ~ .checkmark::after {
      background-image: url('../assets/img/icons/ic_check.svg');
      background-repeat: no-repeat;
      background-size: 24px;
      background-position: center;
    }
  }

  .btn {
    &.left {
      width: 35%;
      margin-right: 5%;
    }

    &.right {
      width: 60%;
    }
  }
}

.price {
  display: block;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 24px;
  border-bottom: 1px solid #6B7174;
  vertical-align: middle;

  .price-title {
    @include p1-fixed;
    margin-bottom: 2px;
  }

  .price-hint {
    @include p4-fixed;
    margin-bottom: 0;
    color: #6B7174;
  }

  .price-value {
    @include h1-fixed;
    margin-bottom: 0;
    color: #B9794A;
  }

  .price-left {
    width: 70%;
    display: inline-block;
  }

  .price-right {
    width: 30%;
    display: inline-block;
    text-align: right;
  }
}

.contact-label {
  @include p5-fixed;
}

.contact {
  @include p0-fixed;
  font-family: $font-pt-serif;
  padding: 6px 0;

  @include size-down(sm) {
    font-size: 18px;
    line-height: 34px;
  }

  img {
    margin-right: 15px;
    width: 24px;
    height: 24px;
  }

  a {
    text-decoration: none;
  }
}

.guests-wrapper {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &.active {
    display: block;

    .guests {
      height: auto;
    }
  }

  .guests {
    position: absolute;
    display: block;
    overflow: hidden;
    height: 0;
    margin-top: 90px;
    padding: 0 24px;
    right: 0;
    background: #ffffff;
    float: left;
    z-index: 1;

    @include size-down(md) {
      top: 50%;
      margin: 0 40px;
      transform: translate(0, -50%);
    }

    @include size-down(sm) {
      margin: 0 24px;
    }

    .guests-item {
      width: 100%;
      padding: 12px 0;
      float: left;

      .guests-item-text {
        width: 65%;
        height: 100%;
        float: left;

        .guests-item-title {
          @include h3-fixed;
          color: $color-background;
          margin-bottom: 0;
        }

        .guests-item-hint {
          @include p4-fixed;
          color: $color-secondary;
          margin-bottom: 0;
        }

        .guests-item-badge {
          @include p4-fixed;
          margin-left: 16px;
          padding: 0 4px;
          background: $color-support2;
          border-radius: 3px;
        }
      }

      .guests-item-picker {
        width: 35%;
        height: 100%;
        padding-top: 10px;
        float: right;
        text-align: right;

        .value {
          height: 28px;
          width: 40px;
          color: #141B1E;
          float: right;
          text-align: center;
        }

        .picker-btn {
          width: 28px;
          height: 28px;
          float: right;
          cursor: pointer;
          background-size: 28px 28px;

          &.disabled {
            cursor: not-allowed;
            opacity: 0.5;
          }

          &.minus {
            background-image: url('../assets/img/icons/ic_minus.svg');
          }

          &.plus {
            background-image: url('../assets/img/icons/ic_plus.svg');
          }
        }
      }
    }

    .btn {
      position: static;
      margin: 0;
    }

    .guests-footer {
      @include p5-fixed;
      width: 100%;
      text-align: center;
      float: left;
      color: $color-secondary;
      padding: 12px 24px;
      margin-bottom: 0;
    }
  }
}

.dates {
  position: relative;
  background: #141B1E;
  border: 1px solid #6B7174;
  box-sizing: border-box;
  border-radius: 2px;
  margin-bottom: 16px;
  outline: none !important;

  @include size-down(md) {
    position: static;
  }

  &.error {
    border: 1px solid $color-danger;
  }

  .form-group {
    display: inline-block;
    width: 50%;
    border: none !important;
    margin-bottom: 0;

    &.border-right {
      border-right: 1px solid $color-secondary !important;
      border-radius: 0;

      &.error {
        border-right: 1px solid $color-danger !important;
      }
    }
  }

  .dates-button {
    padding: 0 24px;

    .btn {
      position: static;
      margin: 0;
    }
  }

  .dates-footer {
    @include p5-fixed;
    width: 100%;
    text-align: center;
    float: left;
    color: $color-secondary;
    padding: 12px 24px;
    margin-bottom: 0;
  }

  .calendar-wrapper {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &.active {
      display: block;

      .calendar {
        height: auto;
      }
    }

    .calendar {
      position: absolute;
      display: block;
      overflow: hidden;
      margin-top: 90px;
      height: 0;
      left: 0;
      right: 0;
      background: #ffffff;
      float: left;
      z-index: 2;
      text-align: center;

      @include size-down(md) {
        top: 50%;
        margin: 0 40px;
        transform: translate(0, -50%);
      }

      @include size-down(sm) {
        margin: 0 24px;
      }
    }
  }
}
