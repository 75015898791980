@import "colors";
@import "typography";

.rdrCalendarWrapper {
  color: #000000;
  font-size: 12px;
}

.rdrDateDisplayWrapper {
  background-color: rgb(239, 242, 247);
}

.rdrDateDisplay {
  margin: 0.833em;
}

.rdrDateDisplayItem {
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
  border: 1px solid transparent;
}

.rdrDateDisplayItem input {
  cursor: pointer;
  height: 2.5em;
  line-height: 2.5em;
  border: 0px;
  background: transparent;
  width: 100%;
  color: #849095;
}

.rdrDateDisplayItemActive {
  border-color: $color-support2;
}

.rdrDateDisplayItemActive input {
  color: #7d888d
}

.rdrMonthAndYearWrapper {
  -webkit-box-align: center;
  align-items: center;
  height: 60px;
  padding-top: 10px;
}

.rdrMonthAndYearPickers {
  font-weight: 600;
}

.rdrMonthAndYearPickers select {
  @include h3;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  border: 0;
  background: transparent;
  padding: 10px 30px 10px 10px;
  border-radius: 4px;
  outline: 0;
  color: $color-primary;
  background: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)'><g id='input' transform='translate(172.000000, 37.000000)' fill='%23B9794A' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>") no-repeat;
  background-position: right 8px center;
  cursor: pointer;
  text-align: center
}

.rdrMonthPicker,
.rdrYearPicker {
  margin: 0 5px
}

.rdrNextPrevButton {
  display: block;
  width: 21px;
  height: 24px;
  margin: 0 0.833em;
  padding: 0;
  border: none;
  background: transparent;
  outline: none !important;
}

.rdrNextPrevButton i {
  display: block;
  padding: 0;
  text-align: center;
  border: none;
  width: 21px;
  height: 24px;
}

.rdrPprevButton i {
  width: 21px;
  height: 24px;
  background: url("../assets/img/icons/ic_left.svg");
  background-size: 21px 24px;
  background-repeat: no-repeat;
}

.rdrNextButton i {
  width: 21px;
  height: 24px;
  background: url("../assets/img/icons/ic_right.svg");
  background-size: 21px 24px;
  background-repeat: no-repeat;
}

.rdrWeekDays {
  padding: 0 0.833em;
}

.rdrMonth {
  padding: 0 0.833em 1.666em 0.833em;
}

.rdrMonth .rdrWeekDays {
  padding: 0;
}

.rdrMonths.rdrMonthsVertical .rdrMonth:first-child .rdrMonthName {
  display: none;
}

.rdrWeekDay {
  @include p5;
  font-weight: 400;
  line-height: 2.667em;
  color: $color-secondary;
}

.rdrDay {
  @include p4;
  background: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  padding: 0;
  line-height: 3.000em;
  height: 3.000em;
  text-align: center;
  color: $color-background;
}

.rdrDay:focus {
  outline: 0;
}

.rdrDayNumber {
  outline: 0;
  font-weight: 300;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  top: 0px;
  bottom: 0px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.rdrDayToday .rdrDayNumber span {
  font-weight: 500
}

.rdrDayToday .rdrDayNumber span:after {
  content: '';
  position: absolute;
  bottom: 4px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  width: 18px;
  height: 2px;
  border-radius: 2px;
  background: $color-primary;
}

.rdrDayToday:not(.rdrDayPassive) .rdrInRange~.rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrStartEdge~.rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrEndEdge~.rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrSelected~.rdrDayNumber span:after {
  background: #fff;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange~.rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrStartEdge~.rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge~.rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrSelected~.rdrDayNumber span {
  color: $color-background;
}

.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  background: $color-support2;
  position: absolute;
  top: 2px;
  left: 0;
  right: 0;
  bottom: 2px;
}

.rdrStartEdge {
  background: linear-gradient(to bottom right, $color-foreground 0%, $color-foreground 50%, $color-support2 50%, $color-support2 100%);
  left: 2px;
  right: 0;

  &.rdrEndEdge {
    background: $color-support2;
    left: 0;
    right: 0;
  }
}

.rdrEndEdge {
  background: linear-gradient(to bottom right, $color-support2 0%, $color-support2 50%, $color-foreground 50%, $color-foreground 100%);
  left: 0;
  right: 2px;
}

.rdrBeforeDisabled,
.rdrAfterDisabled {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.rdrBeforeDisabled {
  background: linear-gradient(to bottom right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgb(248, 248, 248) 50%, rgb(248, 248, 248) 100%);

  &.rdrAfterDisabled {
    background: rgb(248, 248, 248);

    span {
      color: #aeb9bf;
      text-decoration: line-through;
    }
  }
}

.rdrAfterDisabled {
  background: linear-gradient(to bottom right, rgb(248, 248, 248) 0%, rgb(248, 248, 248) 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%);
}

.rdrDayPassive .rdrBeforeDisabled.rdrAfterDisabled span {
  color: #d5dce0;
}

.rdrSelected {
  left: 2px;
  right: 2px;
}

.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0.5;
}

.rdrDayStartPreview {
  background: linear-gradient(to bottom right, $color-foreground 0%, $color-foreground 50%, $color-support2 50%, $color-support2 100%);

  &.rdrDayEndPreview {
    background: $color-support2;
  }
}

.rdrDayInPreview {
  background: $color-support2;
}

.rdrDayEndPreview {
  background: linear-gradient(to bottom right, $color-support2 0%, $color-support2 50%, $color-foreground 50%, $color-foreground 100%);
}

.rdrDayStartPreview,
.rdrDayEndPreview {
  opacity: 1.0;
}

.rdrDefinedRangesWrapper {
  font-size: 12px;
  width: 226px;
  border-right: solid 1px #eff2f7;
  background: #fff;
}

.rdrDefinedRangesWrapper .rdrStaticRangeSelected {
  color: $color-support2;
  font-weight: 600;
}

.rdrStaticRange {
  border: 0;
  cursor: pointer;
  display: block;
  outline: 0;
  border-bottom: 1px solid #eff2f7;
  padding: 0;
  background: #fff
}

.rdrStaticRange:hover .rdrStaticRangeLabel,
.rdrStaticRange:focus .rdrStaticRangeLabel {
  background: #eff2f7;
}

.rdrStaticRangeLabel {
  display: block;
  outline: 0;
  line-height: 18px;
  padding: 10px 20px;
  text-align: left;
}

.rdrInputRanges {
  padding: 10px 0;
}

.rdrInputRange {
  -webkit-box-align: center;
  align-items: center;
  padding: 5px 20px;
}

.rdrInputRangeInput {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
  text-align: center;
  border: solid 1px rgb(222, 231, 235);
  margin-right: 10px;
  color: rgb(108, 118, 122)
}

.rdrInputRangeInput:focus,
.rdrInputRangeInput:hover {
  border-color: rgb(180, 191, 196);
  outline: 0;
  color: #333;
}

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
  content: '';
  border: 1px solid $color-support2;
  border-radius: 1.333em;
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: 0px;
  right: 0px;
  background: transparent;
}

.rdrDayPassive {
  pointer-events: none;
}

.rdrDayPassive .rdrDayNumber span {
  color: #d5dce0;
}

.rdrDayPassive .rdrInRange,
.rdrDayPassive .rdrStartEdge,
.rdrDayPassive .rdrEndEdge,
.rdrDayPassive .rdrSelected,
.rdrDayPassive .rdrDayStartPreview,
.rdrDayPassive .rdrDayInPreview,
.rdrDayPassive .rdrDayEndPreview {
  display: none;
}

.rdrDayDisabled {
  background-color: rgb(248, 248, 248);
}

.rdrDayDisabled .rdrDayNumber span {
  color: #aeb9bf;
  text-decoration: line-through;
}

.rdrDayPassive.rdrDayDisabled .rdrDayNumber span {
  color: #d5dce0;
}

.rdrDayDisabled .rdrInRange,
.rdrDayDisabled .rdrStartEdge,
.rdrDayDisabled .rdrEndEdge,
.rdrDayDisabled .rdrSelected,
.rdrDayDisabled .rdrDayStartPreview,
.rdrDayDisabled .rdrDayInPreview,
.rdrDayDisabled .rdrDayEndPreview {
  -webkit-filter: grayscale(100%) opacity(60%);
  filter: grayscale(100%) opacity(60%);
}

.rdrMonthName {
  text-align: left;
  font-weight: 600;
  color: #849095;
  padding: 0.833em;
}