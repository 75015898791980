$color-foreground: #FFFFFF;
$color-background: #141B1E;
$color-danger: #FF6060;

$color-primary: #B9794A;
$color-primary-hover: #9A6239;
$color-primary-click: #784B2A;

$color-secondary: #7D8587;
$color-secondary-hover: #7D8587;
$color-secondary-click: #7D8587;

$color-support1: #358182;
$color-support1-hover: #206162;
$color-support1-click: #10494A;

$color-support2: #BAD0D7;

.text-primary {
  color: $color-primary;
}

.text-secondary {
  color: $color-secondary;
}

.text-support1 {
  color: $color-support1;
}

.text-support2 {
  color: $color-support2;
}