@import "breakpoints";
@import "colors";

html {
  font-size: 10px;
  @include size-down(xl) {
    font-size: 9px;
  }
  @include size-down(lg) {
    font-size: 9px;
  }
  @include size-down(md) {
    font-size: 8px;
  }
  @include size-down(sm) {
    font-size: 10px;
  }
}

body {
  color: $color-foreground;
  background: $color-background;
}

a {
  color: $color-foreground;
  text-decoration: underline;

  &:hover, &:focus {
    color: $color-primary;
  }

  &:active {
    color: $color-primary-hover;
  }
}

hr {
  border-bottom: 1px solid #6B7174;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}