$grid-breakpoints: (
        xs: 0,
        xsm: 500px,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px
);

@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);
  @if not $n {
    @error "breakpoint `#{$name}` not found in `#{$breakpoints}`";
  }
  @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $max: map-get($breakpoints, $name);
  @return if($max and $max > 0, $max - .02, null);
}

@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

@mixin size-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin size-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin size-between($lower, $upper, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include size-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include size-down($upper, $breakpoints) {
      @content;
    }
  }
}

@mixin size-only($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  $next: breakpoint-next($name, $breakpoints);
  $max: breakpoint-max($next);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include size-up($name, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include size-down($next, $breakpoints) {
      @content;
    }
  }
}

@mixin height-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media screen and (min-height: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin height-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media screen and (max-height: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin height-between($lower, $upper, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min != null and $max != null {
    @media screen and (min-height: $min) and (max-height: $max) {
      @content;
    }
  } @else if $max == null {
    @include size-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include size-down($upper, $breakpoints) {
      @content;
    }
  }
}

@mixin height-only($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  $next: breakpoint-next($name, $breakpoints);
  $max: breakpoint-max($next);

  @if $min != null and $max != null {
    @media screen and (min-height: $min) and (max-height: $max) {
      @content;
    }
  } @else if $max == null {
    @include size-up($name, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include size-down($next, $breakpoints) {
      @content;
    }
  }
}