@import "breakpoints";

$font-argesta-display: "Argesta Display";
$font-pt-serif: "PT Serif";
$font-pt-sans: "PT Sans";

@font-face {
  font-family: $font-argesta-display;
  src: url("../assets/fonts/ArgestaDisplay-Regular.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: $font-pt-serif;
  src: url("../assets/fonts/PTSerif-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $font-pt-serif;
  src: url("../assets/fonts/PTSerif-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: $font-pt-sans;
  src: url("../assets/fonts/PTSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $font-pt-sans;
  src: url("../assets/fonts/PTSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@mixin h {
  font-style: normal;
  font-weight: normal;
}

@mixin h0-xl {
  @include h;
  font-family: $font-argesta-display;
  font-size: 160px;
  line-height: 140px;

  @include size-up(xl) {
    @include height-down(md) {
      font-size: 15vh;
      line-height: 14vh;
    }
  }

  @include size-down(xl) {
    font-size: 144px;
    line-height: 126px;

    @include height-down(md) {
      font-size: 15vh;
      line-height: 14vh;
    }
  }
  @include size-down(lg) {
    font-size: 108px;
    line-height: 95px;

    @include height-down(md) {
      font-size: 15vh;
      line-height: 14vh;
    }
  }
  @include size-down(md) {
    font-size: 100px;
    line-height: 88px;
  }
  @include size-down(sm) {
    font-size: 15vw;
    line-height: 14vw;
  }
}

@mixin h0 {
  @include h;
  font-family: $font-argesta-display;
  font-size: 120px;
  line-height: 100px;

  @include size-down(xl) {
    font-size: 108px;
    line-height: 95px;
  }
  @include size-down(lg) {
    font-size: 81px;
    line-height: 67px;
  }
  @include size-down(md) {
    font-size: 81px;
    line-height: 67px;
  }
  @include size-down(sm) {
    font-size: 15vw;
    line-height: 14vw;
  }
}

@mixin h1 {
  @include h;
  font-family: $font-pt-serif;
  font-size: 4rem;
  line-height: 4.8rem;

  @include size-down(sm) {
    font-size: 3.8rem;
    line-height: 4.6rem;
  }
}

@mixin h2 {
  @include h;
  font-family: $font-argesta-display;
  font-size: 3.2rem;
  line-height: 3.8rem;
}

@mixin h3 {
  @include h;
  font-family: $font-pt-serif;
  font-size: 1.6rem;
  line-height: 2.0rem;
}

@mixin h0-fixed {
  @include h;
  font-family: $font-argesta-display;
  font-size: 120px;
  line-height: 100px;
}

@mixin h1-fixed {
  @include h;
  font-family: $font-pt-serif;
  font-size: 40px;
  line-height: 48px;
}

@mixin h2-fixed {
  @include h;
  font-family: $font-argesta-display;
  font-size: 32px;
  line-height: 38px;
}

@mixin h3-fixed {
  @include h;
  font-family: $font-pt-serif;
  font-size: 16px;
  line-height: 20px;
}

@mixin p {
  font-family: $font-pt-sans;
  font-style: normal;
  font-weight: normal;
}

@mixin p0 {
  @include p;
  font-size: 24px;
  line-height: 34px;

  @include size-down(xl) {
    font-size: 22px;
    line-height: 32px;
  }
  @include size-down(lg) {
    font-size: 20px;
    line-height: 28px;
  }
}

@mixin p1 {
  @include p;
  font-size: 2.0rem;
  line-height: 2.4rem;
}

@mixin p2 {
  @include p;
  font-size: 1.8rem;
  line-height: 2.6rem;
}

@mixin p3 {
  @include p;
  font-size: 1.6rem;
  line-height: 2.0rem;
}

@mixin p4 {
  @include p;
  font-size: 1.4rem;
  line-height: 2.0rem;
}

@mixin p5 {
  @include p;
  font-size: 1.3rem;
  line-height: 1.7rem;
}

@mixin p0-fixed {
  @include p;
  font-size: 24px;
  line-height: 34px;
}

@mixin p1-fixed {
  @include p;
  font-size: 20px;
  line-height: 24px;
}

@mixin p2-fixed {
  @include p;
  font-size: 18px;
  line-height: 26px;
}

@mixin p3-fixed {
  @include p;
  font-size: 16px;
  line-height: 20px;
}

@mixin p4-fixed {
  @include p;
  font-size: 14px;
  line-height: 20px;
}

@mixin p5-fixed {
  @include p;
  font-size: 13px;
  line-height: 17px;
}

.h0-xl {
  @include h0-xl;
}

.h0 {
  @include h0;
}

h1, .h1 {
  @include h1;
}

h2, .h2 {
  @include h2;
}

h3, .h3 {
  @include h3;
}

.p0 {
  @include p0;
}

.p1 {
  @include p1;
}

.p2 {
  @include p2;
}

.p3 {
  @include p3;
}

.p4 {
  @include p4;
}

.p5 {
  @include p5;
}
