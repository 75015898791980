@import "colors";
@import "typography";

.btn {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  padding: 20px;
  font-size: 16px;
  font-family: $font-pt-serif;
  font-style: normal;
  font-weight: bold;
  color: $color-foreground;
  border-radius: 2px;
  transition: all 0.3s ease 0s;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-user-select: none !important;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.btn-sm {
    padding: 8px;
    font-size: 14px;
  }

  &.responsive {
    padding: 2.0rem 4.0rem;
    font-size: 1.6rem;
  }

  &.wider {
    padding: 2.0rem 4.0rem;
  }

  &:hover, &:focus {
    color: $color-foreground;
    box-shadow: none;
    text-decoration: none;
  }

  &:active {
    color: $color-foreground;
    box-shadow: none;
    text-decoration: none;
    outline: 0 !important;
  }

  &.btn-primary {
    background: $color-primary;

    &:hover, &:focus {
      background: $color-primary-hover;
    }

    &:active {
      background: $color-primary-click;
    }
  }

  &.btn-secondary {
    background: $color-secondary;

    &:hover, &:focus {
      background: $color-secondary-hover;
    }

    &:active {
      background: $color-secondary-click;
    }
  }

  &.btn-support1 {
    background: $color-support1;

    &:hover, &:focus {
      background: $color-support1-hover;
    }

    &:active {
      background: $color-support1-click;
    }
  }
}