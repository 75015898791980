@import "colors";
@import "typography";
@import "breakpoints";

@mixin section-padding {
  padding-left: 120px;
  padding-right: 120px;

  @include size-down(xl) {
    padding-left: 90px;
    padding-right: 90px;
  }
  @include size-down(lg) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @include size-down(md) {
    padding-left: 45px;
    padding-right: 45px;
  }
  @include size-down(sm) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.row {
  @include size-down(sm) {
    margin: 0;
  }
}

#head {
  @include section-padding;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  padding-top: 48px;
  padding-bottom: 9.6rem;
  text-align: center;

  @include size-up(xxl) {
    padding-top: 96px;
  }
  @include size-down(sm) {
    padding-top: 24px;
    padding-bottom: 4.2rem;
  }

  &.relative {
    position: relative;
  }

  .head-logo {
    @include size-down(sm) {
      padding-top: 2.2rem;
    }

    img {
      width: 10.0rem;
      height: 10.0rem;

      @include size-down(sm) {
        width: 6.0rem;
        height: 6.0rem;
      }
    }
  }

  .head-contact {
    position: relative;
    background-repeat: no-repeat;
    background-size: 3.6rem;

    ul {
      @include h3;
      margin-top: 3.0rem;
      list-style-type: none;
      padding-inline-start: 0;
      color: $color-primary;

      a {
        color: $color-primary;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    img {
      width: 3.6rem;
      height: 3.6rem;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    &.left {
      img {
        left: 0;
        content: url("../assets/img/icons/ic_instagram.svg");
      }

      ul {
        padding-left: 6.0rem;
        text-align: left;
      }
    }

    &.right {
      img {
        right: 0;
        content: url("../assets/img/icons/ic_map.svg");
      }

      ul {
        padding-right: 6.0rem;
        text-align: right;
      }
    }
  }
}

#lead {
  text-align: center;
  padding-bottom: 12.0rem;
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: #141B1E;
  padding-left: 0 !important;
  padding-right: 0 !important;

  @include size-down(md) {
    padding-bottom: 9.0rem;
  }
  @include size-down(sm) {
    padding-bottom: 6.0rem;
  }

  .lead-main-wrapper {
    position: relative;
    width: 100%;
    height: 100vh;

    @include height-down(sm) {
      height: 80vw;

      @include size-down(md) {
        height: 100vw;
      }
      @include size-down(sm) {
        height: 100vh;
      }
    }

    @include height-down(xsm) {
      @include size-down(md) {
        height: 100vw;
      }
      @include size-down(sm) {
        height: 140vw;
      }
    }

    .lead-main-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      content: url("../assets/img/lead.png");
    }

    .lead-main {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include size-down(sm) {
        justify-content: flex-end;
        padding-bottom: 140px;
      }
    }

    .lead-main-content {
      @include section-padding;

      @include size-down(sm) {
        .btn {
          width: 100%;
        }
      }

      .lead-title {
        @include h0-xl;
      }

      .lead-desc {
        @include p0;
        margin-top: 2.4rem;
        margin-bottom: 4.0rem;
      }
    }

    .lead-photo-desc {
      @include p4;
      position: absolute;
      left: 80px;
      bottom: 80px;
      color: $color-support2;
      margin-top: 3.2rem;
      width: 26.0rem;
      text-align: start;
    }

    .lead-arrow {
      position: absolute;
      padding-top: 40px;
      padding-bottom: 80px;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(0deg, $color-background 10.03%, rgba(0, 0, 0, 0) 79.18%);

      img {
        content: url("../assets/img/icons/ic_arrow_down.svg");
      }
    }

  }

  #lead-intro {
    @include section-padding;
    @include h0;
    padding-top: 10rem;
    font-size: 4.8rem;
    line-height: 6.0rem;
    color: $color-primary;
    text-align: left;

    @include size-down(xl) {
      font-size: 4.8rem;
      line-height: 6.0rem;
    }
    @include size-down(lg) {
      font-size: 3.6rem;
      line-height: 4.5rem;
    }
    @include size-down(sm) {
      font-size: 2.4rem;
      line-height: 3.0rem;
      padding-top: 6rem;
    }
  }

  .lead-map {
    @include section-padding;
    padding-top: 4.8rem;
    text-align: left;

    .lead-map-info {
      @include p4;
      color: #949494;
      margin: auto;
    }

    .lead-map-img {
      @include size-down(sm) {
        text-align: center;
        margin-bottom: 24px;
      }

      img {
        width: 17.0rem;
        height: 16.0rem;
        content: url("../assets/img/map.svg");

        @include size-down(sm) {
          width: 170px;
          height: 160px;
        }
      }
    }
  }
}

#gallery {
  position: relative;

  .arrows {
    position: absolute;
    left: 4.8rem;
    bottom: 24.8rem;

    @include size-down(sm) {
      position: relative;
      left: 0;
      bottom: 0;
    }

    .arrow {
      position: relative;
      width: 10.2rem;
      height: 6.4rem;
      padding-left: 16px;
      padding-right: 16px;
      background-color: $color-background;
      border: none;
      z-index: 1;
      cursor: pointer;

      @include size-down(sm) {
        width: 50%;
        padding-left: 24px;
        padding-right: 24px;
      }

      &:hover {
        background-color: #27343A;
      }

      &.arrow-prev {
        text-align: left;

        i {
          background-image: url("../assets/img/icons/ic_arrow_left.svg");
        }
      }

      &.arrow-next {
        text-align: right;

        i {
          background-image: url("../assets/img/icons/ic_arrow_right.svg");
        }
      }

      i {
        position: relative;
        display: inline-block;
        width: 2.0rem;
        height: 1.8rem;
        background-repeat: no-repeat;
        background-size: 1.8rem 2.0rem;
      }
    }
  }

  .gallery-image-slider {
    width: 100%;
    height: 100%;
    display: block !important;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      &.img1 {
        content: url("../assets/img/gallery/1-min.jpg");
        @include size-down(md) {
          content: url("../assets/img/gallery/1m-min.jpg");
        }
      }

      &.img2 {
        content: url("../assets/img/gallery/2-min.jpg");
        @include size-down(md) {
          content: url("../assets/img/gallery/2m-min.jpg");
        }
      }

      &.img3 {
        content: url("../assets/img/gallery/3-min.jpg");
        @include size-down(md) {
          content: url("../assets/img/gallery/3m-min.jpg");
        }
      }

      &.img4 {
        content: url("../assets/img/gallery/4-min.jpg");
        @include size-down(md) {
          content: url("../assets/img/gallery/4m-min.jpg");
        }
      }

      &.img5 {
        content: url("../assets/img/gallery/5-min.jpg");
        @include size-down(md) {
          content: url("../assets/img/gallery/5m-min.jpg");
        }
      }

      &.img6 {
        content: url("../assets/img/gallery/6-min.jpg");
        @include size-down(md) {
          content: url("../assets/img/gallery/6m-min.jpg");
        }
      }

      &.img7 {
        content: url("../assets/img/gallery/7-min.jpg");
        @include size-down(md) {
          content: url("../assets/img/gallery/7m-min.jpg");
        }
      }

      &.img8 {
        content: url("../assets/img/gallery/8-min.jpg");
        @include size-down(md) {
          content: url("../assets/img/gallery/8m-min.jpg");
        }
      }

      &.img9 {
        content: url("../assets/img/gallery/9-min.jpg");
        @include size-down(md) {
          content: url("../assets/img/gallery/9m-min.jpg");
        }
      }

      &.img10 {
        content: url("../assets/img/gallery/10-min.jpg");
        @include size-down(md) {
          content: url("../assets/img/gallery/10m-min.jpg");
        }
      }

      &.img11 {
        content: url("../assets/img/gallery/11-min.jpg");
        @include size-down(md) {
          content: url("../assets/img/gallery/11m-min.jpg");
        }
      }

      &.img12 {
        content: url("../assets/img/gallery/12-min.jpg");
        @include size-down(md) {
          content: url("../assets/img/gallery/12m-min.jpg");
        }
      }

      &.img13 {
        content: url("../assets/img/gallery/13-min.jpg");
        @include size-down(md) {
          content: url("../assets/img/gallery/13m-min.jpg");
        }
      }

      &.img14 {
        content: url("../assets/img/gallery/14-min.jpg");
        @include size-down(md) {
          content: url("../assets/img/gallery/14m-min.jpg");
        }
      }
    }
  }

  .gallery-info-slider {
    width: 38.0rem;
    height: 20.0rem;
    position: absolute;
    left: 4.8rem;
    bottom: 4.8rem;
    z-index: 10;

    @include size-down(sm) {
      width: 100%;
      position: relative;
      left: 0;
      bottom: 0;
    }

    .gallery-info {
      width: 38.0rem;
      height: 20.0rem;
      background-color: $color-foreground;
      padding: 4.0rem;

      h2 {
        color: $color-background;
      }

      p {
        @include p3;
        color: $color-secondary;
      }
    }
  }
}

#features {
  @include section-padding;
  padding-bottom: 13.0rem;
  background: #B9794A;

  @include size-down(sm) {
    padding-bottom: 6.0rem;
  }

  .features-text {
    @include h1;
    font-size: 4.8rem;
    line-height: 6.0rem;
    padding-top: 16.0rem;
    padding-bottom: 6.0rem;
    position: sticky;
    top: 0;

    @include size-down(lg) {
      position: static;
    }

    @include size-down(sm) {
      padding-top: 8.0rem;
    }
  }

  .features-list {
    padding-top: 16.0rem;

    @include size-down(lg) {
      padding-top: 0;
    }

    .feature-title {
      margin-left: 6.0rem;
      padding-top: 5.6rem;
      padding-left: 0;
      background-size: 4.0rem 4.0rem;
      background-repeat: no-repeat;

      @include size-down(lg) {
        margin-left: 0;
        padding-right: 6.0rem;
      }

      &.no1 {
        background-image: url('../assets/img/features/1.svg');
      }

      &.no2 {
        background-image: url('../assets/img/features/2.svg');
      }

      &.no3 {
        background-image: url('../assets/img/features/3.svg');
      }

      &.no4 {
        background-image: url('../assets/img/features/4.svg');
      }

      &.no5 {
        background-image: url('../assets/img/features/5.svg');
      }

      &.no6 {
        background-image: url('../assets/img/features/6.svg');
      }

      &.no7 {
        background-image: url('../assets/img/features/7.svg');
      }

      &.no8 {
        background-image: url('../assets/img/features/8.svg');
      }
    }

    .feature-desc {
      @include p2;
      color: #351600;
      padding-top: 1.6rem;
      padding-bottom: 4.8rem;
      padding-left: 6.0rem;

      @include size-up(xxl) {
        padding-right: 12.0rem;
      }
      @include size-down(lg) {
        padding-left: 0;
        padding-right: 6.0rem;
      }
      @include size-down(sm) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

#pricing {
  padding-left: 0;
  padding-right: 0;
  background-image: url('../assets/img/pricing.jpg');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100%;
  background-color: #B9794A;

  .pricing-cards {
    background: linear-gradient(180deg, #B9794A 50.03%, rgba(219, 182, 147, 0) 79.18%);
    color: #141B1E;
    padding-left: 12.0rem;
    padding-right: 12.0rem;

    @include size-down(md) {
      padding-left: 6.0rem;
      padding-right: 6.0rem;
    }

    @include size-down(sm) {
      padding-left: 0;
      padding-right: 0;
    }

    .pricing-card {
      background: #fff;
      border-right: 1px solid rgba(247, 247, 247, 1);
      padding-top: 4.0rem;
      padding-bottom: 8.0rem;
      text-align: center;

      @include size-down(md) {
        border-right: none;
        border-bottom: 1px solid rgba(247, 247, 247, 1);
      }

      &.last {
        background: rgba(247, 247, 247, 1);
        padding: 5.0rem 4.8rem;

        .pricing-text {
          text-align: left;
          margin-top: 2.4rem;

          .p1 {
            font-family: "Argesta Display";
            margin-bottom: 0.8rem;
          }

          .p3 {
            margin-bottom: 0;
          }

          .pricing-info2 {
            color: #B9794A;
            font-family: "Argesta Display";
          }
        }
      }

      .pricing-info {
        @include p3;
        color: #7D8587;
      }

      .pricing-price {
        margin-top: 6.4rem;
        color: $color-primary;
        font-family: $font-argesta-display;
        font-size: 8.0rem;
        line-height: 8.0rem;
      }
    }
  }

  .pricing-hints {
    background: $color-support2;
    color: $color-background;

    .row {
      height: 100%;
    }

    .pricing-hint {
      @include p3;
      height: 100%;
      padding-top: 3.2rem;
      padding-bottom: 3.2rem;
      padding-left: 10.4rem;
      background-size: 4.8rem;
      background-repeat: no-repeat;
      background-position-x: 3.2rem;
      background-position-y: center;

      a {
        color: $color-background;
      }

      &.hint-1 {
        background-image: url("../assets/img/icons/ic_night.svg");
      }

      &.hint-2 {
        background-image: url("../assets/img/icons/ic_star.svg");
      }

      &.hint-3 {
        background-image: url("../assets/img/icons/ic_list.svg");
      }
    }
  }
}

#booking {
  @include section-padding;
  text-align: center;
  padding-top: 15.0rem;
  padding-bottom: 16.0rem;

  @include size-down(sm) {
    padding-top: 12.0rem;
  }

  .booking-title {
    @include h0;
  }

  .booking-desc {
    @include p0;
    margin-top: 4.0rem;
    margin-bottom: 4.0rem;
  }
}

#footer {
  @include section-padding;
  @include p3;
  padding-top: 6.4rem;
  padding-bottom: 4.0rem;
  background: $color-background;
  line-height: 3rem;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: $color-foreground;
    }
  }

  ul {
    list-style-type: none;
    padding-inline-start: 0;
    margin-bottom: 2rem;

    &.no-images {
      padding-inline-start: 2.8rem;
    }

    li img {
      width: 1.8rem;
      height: 1.8rem;
      margin-right: 1rem;
    }
  }

  hr {
    background-color: $color-foreground;
    opacity: 0.1;
    margin-inline-start: 0;
    margin-inline-end: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-top: 6.4rem;
    margin-bottom: 2.4rem;
  }

  .footer-logo img {
    width: 8.0rem;
    height: 8.0rem;
    margin-bottom: 2rem;
    content: url('../assets/img/logo.svg');
  }

  .footer-address {
    padding-left: 3.8rem;
    background-image: url('../assets/img/icons/ic_marker.svg');
    background-repeat: no-repeat;
    background-size: 1.2rem 1.6rem;
    background-position-y: 0.5rem;
    background-position-x: 1.3rem;
    color: #FFEFD7;

    @include size-down(sm) {
      margin-bottom: 2rem;
    }
  }

  .footer-copy {
    @include p3;
    padding-bottom: 2.4rem;
    color: $color-secondary;

    @include size-down(md) {
      color: $color-foreground;
      text-align: center;
    }
  }

  .footer-tomo {
    @include p3;
    padding-bottom: 2.4rem;
    text-align: right;

    a {
      color: $color-secondary;
      text-decoration: none;

      img {
        width: 100px;
        height: 24px;
        margin-left: 8px;
        content: url("../assets/img/tomo_logo_gray.svg")
      }

      &:hover img {
        content: url("../assets/img/tomo_logo_white.svg")
      }
    }

    @include size-down(md) {
      text-align: center;
    }
  }
}

#document {
  @include section-padding;

  @include size-up(xxl) {
    padding: 0 300px;
  }

  .document-title {
    @include h0;
    text-align: center;
  }

  .document-content {
    @include p1;
    margin-top: 12.0rem;
    text-align: left;
    color: $color-support2;
  }
}